<template>
    <LeaveOfAbsence clientCode="CME"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/CME/cme_variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "CMELeaveOfAbsence"
};
</script>
<style lang="scss" scoped src="@/assets/css/client/CME/cmeloa.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/CME/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/CME/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/CME/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/CME/feedback_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/CME/send_email_modal.scss"></style>